// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero--globe {
    position: relative;
    z-index: 100;
    margin: 0 auto;
    border: 1px solid red;
   
  }

  .mars-globe{
animation:rotateMars infinite 10s ease-in-out ;
  }
 
  
  
  @keyframes rotateMars {
    from {
      transform: rotateY(0deg);
      
    }
    to {
      transform: rotateY(180deg);
     
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Globe/Globe.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,qBAAqB;;EAEvB;;EAEA;AACF,8CAA8C;EAC5C;;;;EAIA;IACE;MACE,wBAAwB;;IAE1B;IACA;MACE,0BAA0B;;IAE5B;EACF","sourcesContent":[".hero--globe {\n    position: relative;\n    z-index: 100;\n    margin: 0 auto;\n    border: 1px solid red;\n   \n  }\n\n  .mars-globe{\nanimation:rotateMars infinite 10s ease-in-out ;\n  }\n \n  \n  \n  @keyframes rotateMars {\n    from {\n      transform: rotateY(0deg);\n      \n    }\n    to {\n      transform: rotateY(180deg);\n     \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
