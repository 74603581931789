// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.app-container {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.app-container > img {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
  object-fit: fill;
}

@media only screen and (max-width: 769px) {
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,MAAM;EACN,OAAO;EACP,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE;IACE,SAAS;IACT,UAAU;IACV,kBAAkB;EACpB;AACF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\n.app-container {\n  width: 100%;\n  min-height: 100vh;\n  overflow: hidden;\n  position: relative;\n}\n\n.app-container > img {\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: -1000;\n  object-fit: fill;\n}\n\n@media only screen and (max-width: 769px) {\n  body {\n    margin: 0;\n    padding: 0;\n    overflow-x: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
