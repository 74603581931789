// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/MDNichromeTest-Infra.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/assests/Home/Maskgroup.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0px;
  padding: 0px;
  font-family: "MDNichromeTest-Infra", sans-serif;
}

@font-face {
  font-family: " MDNichromeTest-Infra";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
body {
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

body::-webkit-scrollbar {
  display: none;
}
canvas {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,+CAA+C;AACjD;;AAEA;EACE,oCAAoC;EACpC,+DAA+D;AACjE;AACA;EACE,WAAW;EACX,YAAY;EACZ,yDAA0D;AAC5D;;AAEA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;AAC1B","sourcesContent":["* {\n  margin: 0px;\n  padding: 0px;\n  font-family: \"MDNichromeTest-Infra\", sans-serif;\n}\n\n@font-face {\n  font-family: \" MDNichromeTest-Infra\";\n  src: url(\"./fonts/MDNichromeTest-Infra.otf\") format(\"opentype\");\n}\nbody {\n  width: 100%;\n  height: 100%;\n  background-image: url(\"../src/assests/Home/Maskgroup.png\");\n}\n\nbody::-webkit-scrollbar {\n  display: none;\n}\ncanvas {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
