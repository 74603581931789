// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wrapper {
  display: flex;
  justify-content: space-between;
  background-color: #343235cc;
  padding-right: 5%;
  margin-top: 6%;
  position: sticky;
  bottom: 1px;
  right: 0px;
  width: 100%;
}
.icon {
  height: 90px;
  width: 90px;
  object-fit: fill;
}
@media (max-width: 1024px) {
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .footer-wrapper {
    display: flex;
    justify-content: space-around;
    position: static;
    bottom: 0px;
    width: 100vw;
    left: 0px;
  }
  .icon {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }
  .empty-div {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/common/footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,2BAA2B;EAC3B,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,UAAU;EACV,WAAW;AACb;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;AACA;EACE;IACE,SAAS;IACT,UAAU;IACV,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,SAAS;EACX;EACA;IACE,YAAY;IACZ,WAAW;IACX,iBAAiB;EACnB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".footer-wrapper {\n  display: flex;\n  justify-content: space-between;\n  background-color: #343235cc;\n  padding-right: 5%;\n  margin-top: 6%;\n  position: sticky;\n  bottom: 1px;\n  right: 0px;\n  width: 100%;\n}\n.icon {\n  height: 90px;\n  width: 90px;\n  object-fit: fill;\n}\n@media (max-width: 1024px) {\n  body {\n    margin: 0;\n    padding: 0;\n    overflow-x: hidden;\n  }\n  .footer-wrapper {\n    display: flex;\n    justify-content: space-around;\n    position: static;\n    bottom: 0px;\n    width: 100vw;\n    left: 0px;\n  }\n  .icon {\n    height: 50px;\n    width: 50px;\n    object-fit: cover;\n  }\n  .empty-div {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
